import { FUZZINESS_LEVEL_DEFAULT } from '@constants';
import type { PepReportsModel } from '@models';
import type { PepEntityTypeEnum, PepSexEnum, PepTagEnum } from '@models/enums';
import { PepQueryFieldEnum } from '@models/enums';
import type { AutocompleteOption, DateRangeValue } from '@models/types';
import { pepsApiSlice } from '@modules/dashboard/api/peps-api';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type PepFilersQueryProps = {
    inputSearchText?: string;
    [PepQueryFieldEnum.SEARCH]: string;
    [PepQueryFieldEnum.TAGS]: Array<AutocompleteOption<PepTagEnum>>;
    [PepQueryFieldEnum.LINKS]?: Array<AutocompleteOption<string>>;
    [PepQueryFieldEnum.CATEGORIES]: Array<AutocompleteOption<PepEntityTypeEnum>>;
    [PepQueryFieldEnum.GENDER]: AutocompleteOption<PepSexEnum> | null;
    [PepQueryFieldEnum.COUNTRIES]: Array<AutocompleteOption<string>>;
    [PepQueryFieldEnum.DATE_RANGE]: DateRangeValue;
    [PepQueryFieldEnum.SEARCH_UUID]: string | null;
    [PepQueryFieldEnum.FUZZINESS_LEVEL]: number;
    [PepQueryFieldEnum.PHONETICS]: boolean;
};

export type PepFiltersSlice = PepFilersQueryProps & {
    isOpen: boolean;
    applyCount: number;
    pathSearch: string | null;
};

const initialState: PepFiltersSlice = {
    inputSearchText: '',
    [PepQueryFieldEnum.SEARCH_UUID]: '',
    [PepQueryFieldEnum.SEARCH]: '',
    [PepQueryFieldEnum.TAGS]: [],
    [PepQueryFieldEnum.LINKS]: [],
    [PepQueryFieldEnum.CATEGORIES]: [],
    [PepQueryFieldEnum.COUNTRIES]: [],
    [PepQueryFieldEnum.GENDER]: null,
    [PepQueryFieldEnum.DATE_RANGE]: null,
    [PepQueryFieldEnum.FUZZINESS_LEVEL]: FUZZINESS_LEVEL_DEFAULT,
    [PepQueryFieldEnum.PHONETICS]: true,
    applyCount: 0,
    pathSearch: null,
    isOpen: false,
};

export const pepFiltersSlice = createSlice({
    name: 'pepFiltersSlice',
    initialState,
    reducers: {
        openFiltersMenu: (state) => {
            state.isOpen = true;
        },
        hideFiltersMenu: (state) => {
            state.isOpen = false;
        },
        setDateRange: (state, { payload }: PayloadAction<DateRangeValue>) => {
            state[PepQueryFieldEnum.DATE_RANGE] = payload;
        },
        setTags: (state, { payload }: PayloadAction<Array<AutocompleteOption<PepTagEnum>>>) => {
            state[PepQueryFieldEnum.TAGS] = payload;
        },
        setLinks: (state, { payload }: PayloadAction<Array<AutocompleteOption<string>>>) => {
            state[PepQueryFieldEnum.LINKS] = payload;
        },
        setCategories: (
            state,
            { payload }: PayloadAction<Array<AutocompleteOption<PepEntityTypeEnum>>>,
        ) => {
            state[PepQueryFieldEnum.CATEGORIES] = payload;
        },
        setCountries: (state, { payload }: PayloadAction<Array<AutocompleteOption<string>>>) => {
            state[PepQueryFieldEnum.COUNTRIES] = payload;
        },
        setGenders: (state, { payload }: PayloadAction<AutocompleteOption<PepSexEnum> | null>) => {
            state[PepQueryFieldEnum.GENDER] = payload;
        },
        setSearchTextFilter: (state, { payload }: PayloadAction<string>) => {
            state[PepQueryFieldEnum.SEARCH] = payload;
        },
        setFuzzinessLevelFilter: (state, { payload }: PayloadAction<number>) => {
            state[PepQueryFieldEnum.FUZZINESS_LEVEL] = payload;
        },
        setPhoneticsFilter: (state, { payload }: PayloadAction<boolean>) => {
            state[PepQueryFieldEnum.PHONETICS] = payload;
        },
        increaseApplyCount: (state) => {
            state.applyCount += 1;
        },
        setInputSearchTextFilter: (state, { payload }: PayloadAction<string>) => {
            state.inputSearchText = payload;
        },
        setFilter: (state, { payload }: PayloadAction<PepFilersQueryProps>) => {
            state.inputSearchText = payload.inputSearchText || payload[PepQueryFieldEnum.SEARCH];
            state[PepQueryFieldEnum.SEARCH] = payload[PepQueryFieldEnum.SEARCH] || '';
            state[PepQueryFieldEnum.TAGS] = payload[PepQueryFieldEnum.TAGS] || [];
            state[PepQueryFieldEnum.LINKS] = payload[PepQueryFieldEnum.LINKS] || [];
            state[PepQueryFieldEnum.CATEGORIES] = payload[PepQueryFieldEnum.CATEGORIES] || [];
            state[PepQueryFieldEnum.COUNTRIES] = payload[PepQueryFieldEnum.COUNTRIES] || [];
            state[PepQueryFieldEnum.GENDER] = payload[PepQueryFieldEnum.GENDER] || null;
            state[PepQueryFieldEnum.DATE_RANGE] = payload[PepQueryFieldEnum.DATE_RANGE] || null;
            state[PepQueryFieldEnum.SEARCH_UUID] = payload[PepQueryFieldEnum.SEARCH_UUID] || null;
            state[PepQueryFieldEnum.FUZZINESS_LEVEL] = payload[PepQueryFieldEnum.FUZZINESS_LEVEL]
                ? payload[PepQueryFieldEnum.FUZZINESS_LEVEL]
                : 0;
            state[PepQueryFieldEnum.PHONETICS] = payload[PepQueryFieldEnum.PHONETICS]
                ? payload[PepQueryFieldEnum.PHONETICS]?.toString() === 'true'
                : true;
        },
        setPathSearch: (state, { payload }: PayloadAction<string>) => {
            state.pathSearch = payload;
        },
        cleanAll: (state) => {
            state.inputSearchText = '';
            state[PepQueryFieldEnum.TAGS] = [];
            state[PepQueryFieldEnum.LINKS] = [];
            state[PepQueryFieldEnum.CATEGORIES] = [];
            state[PepQueryFieldEnum.COUNTRIES] = [];
            state[PepQueryFieldEnum.GENDER] = null;
            state[PepQueryFieldEnum.DATE_RANGE] = null;
            state[PepQueryFieldEnum.SEARCH_UUID] = '';
            state[PepQueryFieldEnum.SEARCH] = '';
            state.pathSearch = '';
            state[PepQueryFieldEnum.FUZZINESS_LEVEL] = FUZZINESS_LEVEL_DEFAULT;
            state[PepQueryFieldEnum.PHONETICS] = true;
        },
        cleanAllFilter: (state) => {
            state[PepQueryFieldEnum.TAGS] = [];
            state[PepQueryFieldEnum.LINKS] = [];
            state[PepQueryFieldEnum.CATEGORIES] = [];
            state[PepQueryFieldEnum.COUNTRIES] = [];
            state[PepQueryFieldEnum.GENDER] = null;
            state[PepQueryFieldEnum.DATE_RANGE] = null;
            state[PepQueryFieldEnum.SEARCH_UUID] = '';
            state[PepQueryFieldEnum.SEARCH] = '';
            state.pathSearch = '';
            state[PepQueryFieldEnum.FUZZINESS_LEVEL] = FUZZINESS_LEVEL_DEFAULT;
            state[PepQueryFieldEnum.PHONETICS] = true;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            pepsApiSlice.endpoints.getPepList.matchFulfilled,
            (
                state,
                action: {
                    type: string;
                    payload: PepReportsModel;
                },
            ) => {
                if (action.payload) {
                    state.searchUuid = action.payload.search_uuid;
                }
            },
        );
    },
});
