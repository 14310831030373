import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '@store/root-reducer';
import axios from 'axios';

const baseAxios = axios.create({
    baseURL: process.env.REACT_APP_LOGS_API_BASE_URL,
});

type LogMessage = {
    message: string;
    data?: unknown;
};

const client_type = process.env.REACT_APP_LOGS_CLIENT_TYPE || 'prod';

export const useLogger = () => {
    const user = useSelector((state: RootState) => state.auth?.user?.info);
    const organizationId = useSelector(
        (state: RootState) =>
            state.dashboard?.session?.organizationId || state.adminPanel?.session?.organizationId,
    );
    const { sdkOptions } = useSelector((state: RootState) => state.docsVerification);

    const logger = useMemo(() => {
        if (!organizationId && sdkOptions) {
            const meta = {
                verificationId: sdkOptions.id,
                settings: sdkOptions.settings,
                checks: sdkOptions.checks,
            };

            const getMessage = (type: string, message: string, data: unknown) =>
                `DocVerWidget ${type}. Message: ${message}. Data: ${JSON.stringify(
                    data,
                )}. Meta: ${JSON.stringify(meta)}`;

            return {
                log: async ({ message, data }: LogMessage) => {
                    try {
                        await baseAxios.post('', {
                            msg: getMessage('Log', message, data),
                            level: 'default',
                            client_type,
                        });
                    } catch {
                        /* empty */
                    }
                },
                error: async ({ message, data }: LogMessage) => {
                    try {
                        await baseAxios.post('', {
                            msg: getMessage('Error', message, data),
                            level: 'error',
                            client_type,
                        });
                    } catch {
                        /* empty */
                    }
                },
                warn: async ({ message, data }: LogMessage) => {
                    try {
                        await baseAxios.post('', {
                            msg: getMessage('Warning', message, data),
                            level: 'info',
                            client_type,
                        });
                    } catch {
                        /* empty */
                    }
                },
            };
        }

        const meta = {
            organizationId,
            user,
        };

        const getMessage = (type: string, message: string, data: unknown) =>
            `Dashboard ${type}. Message: ${message}. Data: ${JSON.stringify(
                data,
            )}. Meta: ${JSON.stringify(meta)}`;

        return {
            log: async ({ message, data }: LogMessage) => {
                try {
                    await baseAxios.post('', {
                        msg: getMessage('Log', message, data),
                        level: 'default',
                        client_type,
                    });
                } catch {
                    /* empty */
                }
            },
            error: async ({ message, data }: LogMessage) => {
                try {
                    await baseAxios.post('', {
                        msg: getMessage('Error', message, data),
                        level: 'error',
                        client_type,
                    });
                } catch {
                    /* empty */
                }
            },
            warn: async ({ message, data }: LogMessage) => {
                try {
                    await baseAxios.post('', {
                        msg: getMessage('Warning', message, data),
                        level: 'info',
                        client_type,
                    });
                } catch {
                    /* empty */
                }
            },
        };
    }, [sdkOptions, organizationId, user]);

    return {
        logger,
    };
};
