import type { DomainSettingsModel } from '@modules/shared/models/white-label/domain-settings-model';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryApi } from '@reduxjs/toolkit/src/query';
import type { FetchBaseQueryArgs } from '@reduxjs/toolkit/src/query/fetchBaseQuery';
import type { RootState } from '@store/root-reducer';
import { getPropertyStartWith } from '@utils/get-property-start-with';

export const baseQueryRequest = async ({
    state,
    baseQuery,
    args,
    api,
    extraOptions,
    isUseCredentials = true,
    prepareHeaders,
}: {
    state: RootState;
    baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;
    args: string | FetchArgs;
    api: BaseQueryApi;
    extraOptions: Record<string, unknown>;
    isUseCredentials?: boolean;
    prepareHeaders?: FetchBaseQueryArgs['prepareHeaders'];
}) => {
    const getDomainSettingsQueryKey = getPropertyStartWith(
        state.publicApi?.queries,
        'getDomainSettings',
    );

    let result: ReturnType<typeof baseQuery>;

    if (
        (getDomainSettingsQueryKey &&
            state.publicApi?.queries?.[getDomainSettingsQueryKey]?.data) ||
        state.domain?.settings?.domain_settings?.api
    ) {
        const data =
            (state.publicApi?.queries?.[getDomainSettingsQueryKey]?.data as DomainSettingsModel) ||
            state.domain.settings;

        result = await fetchBaseQuery({
            baseUrl: `https://${data.domain_settings.api}`,
            ...(isUseCredentials
                ? {
                      credentials: 'include',
                  }
                : {}),
            ...(prepareHeaders ? { prepareHeaders } : {}),
        })(args, api, extraOptions);
    } else {
        result = await baseQuery(args, api, extraOptions);
    }

    return result;
};
