export enum ApiTagEnum {
    ORGANIZATIONS = 'Organizations',
    INVITATIONS = 'Invitations',
    DEVELOPER_SETTINGS = 'DeveloperSettings',
    ORGANIZATION_INFO = 'OrganizationInfo',
    VERIFICATION = 'Verification',
    APPLICANTS = 'Applicants',
    APPLICANT_ALERTS = 'ApplicantALerts',
    APPLICANT = 'Applicant',
    FILE_UPLOADS = 'FileUploads',
    PEPS = 'Peps',
    CONTACT_US = 'ContactUs',
    LIST_TEAM = 'ListTeam',
    CURRENT_ORGANIZATION = 'Current Organization',
    CURRENT_BILLING_ORGANIZATION = 'Current Billing Organization',
    PEP = 'PEP',
    UPDATE_PROFILE = 'UPDATE_PROFILE',
}
