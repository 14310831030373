import { initReactI18next } from 'react-i18next';
import { LangEnum } from '@models/enums';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const localizationCreated = false;

const langs = [LangEnum.EN, LangEnum.RU];

type JsonsProps = {
    [key: string]: {
        [key: string]: string;
    };
};

const getAllJsons = (lang: LangEnum) => {
    // @ts-expect-error valid
    const contextEn = require?.context(`./locales/en`, true, /.json$/);

    if (lang === LangEnum.EN) {
        return contextEn.keys().map((jsonPath: string) => contextEn(jsonPath));
    }

    return {};
};

export const createLocalization = (lang: LangEnum) => {
    const getFlatJsons = (jsons: JsonsProps) => {
        let obj = {};

        Object.values(jsons).forEach((item) => {
            obj = {
                ...obj,
                ...item,
            };
        });

        return obj;
    };

    const translation = getFlatJsons(getAllJsons(lang));

    const resources = langs.reduce(
        (acc, item) => ({
            ...acc,
            [item]: {
                translation,
            },
        }),
        {},
    );

    i18n.use(LanguageDetector)
        .use(initReactI18next)
        .init({
            fallbackLng: LangEnum.EN,
            lng: lang,
            resources,
            // debug: true, // Use this to see what's going on in console
            // react: {
            //     useSuspense: false,
            // },
            // keySeparator: false,
            interpolation: {
                escapeValue: false,
            },
        });
};

export const changeLanguage = (lang: LangEnum) => {
    if (!localizationCreated) {
        createLocalization(lang);
    }

    i18n.changeLanguage(lang);
};
