import React from 'react';
import type { IconProps } from '@models/types';

export const UserIcon: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10ZM12 9C11.4477 9 11 9.44772 11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10C13 9.44772 12.5523 9 12 9Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M17.6874 20.2263C20.2933 18.4212 22 15.4099 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 15.4099 3.70674 18.4212 6.31257 20.2263C6.39436 20.3037 6.48922 20.3675 6.5935 20.4139C8.15234 21.4177 10.0082 22 12 22C13.9918 22 15.8477 21.4177 17.4065 20.4139C17.5108 20.3675 17.6056 20.3037 17.6874 20.2263ZM15.9577 18.9541C14.7906 19.6197 13.4397 20 12 20C10.5603 20 9.20941 19.6197 8.04233 18.9541C8.30448 17.2804 9.75272 16 11.5 16H12.5C14.2473 16 15.6955 17.2804 15.9577 18.9541ZM17.6765 17.6371C16.9132 15.5164 14.8837 14 12.5 14H11.5C9.11632 14 7.0868 15.5164 6.3235 17.6371C4.88733 16.191 4 14.1991 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 14.1991 19.1127 16.191 17.6765 17.6371Z'
            fill='currentColor'
        />
    </svg>
);
