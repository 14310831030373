export enum InfiniteScrollTableNamesEnum {
    PEP_REPORT = 'pepReport',
    APPLICANT_PERSON = 'applicantPerson',
    APPLICANT_ORGANIZATION = 'applicantOrganization',
    APPLICANT_ALERTS = 'applicantAlerts',
    PEP_HISTORY = 'pepHistory',
    OPERATIONS_HISTORY = 'expensesHistory',
    PAYMENTS_HISTORY = 'paymentsHistory',
    DOCS_VERIFICATION_RESULTS = 'docsVerificationResults',
    WEBHOOKS_HISTORY = 'webHooksHistory',
    DATA_SOURCES = 'dataSources',
    SEARCH_APPLICANT = 'searchApplicant',
    APPLICANT_VERIFICATION_RESULTS = 'applicantDocsVerificationResults',
    APPLICANT_PEP_HISTORY_RESULTS = 'applicantPepHistoryResults',
    APPLICANT_DOCS = 'applicantDocs',
}
