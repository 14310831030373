import type {
    DocsVerificationDocumentTypeEnum,
    DocsVerificationStatusEnum,
    FilterFieldArrayOptionNamesEnum,
    FilterFieldDateNamesEnum,
} from '@models/enums';
import { FilterOrganizationNames } from '@models/enums';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type OrganizationDocumentVerificationsFiltersState = {
    [FilterFieldDateNamesEnum.CREATED_AT_DATE_RANGE]: DateRangeValue;
    [FilterFieldArrayOptionNamesEnum.DOCUMENT_TYPES]: Array<
        AutocompleteOption<DocsVerificationDocumentTypeEnum>
    >;
    [FilterFieldArrayOptionNamesEnum.STATUSES]: Array<
        AutocompleteOption<DocsVerificationStatusEnum>
    >;
    [FilterFieldArrayOptionNamesEnum.PROFILES]: Array<AutocompleteOption<string>>;
};

export type OrganizationDocumentVerificationFiltersSliceState =
    FilterMenuProps<OrganizationDocumentVerificationsFiltersState>;

export const organizationDocumentVerificationsFiltersInitState: OrganizationDocumentVerificationsFiltersState =
    {
        createdAtDateRange: null,
        documentTypes: [],
        statuses: [],
        profiles: [],
    };

const initialState: OrganizationDocumentVerificationFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: organizationDocumentVerificationsFiltersInitState,
};

export const organizationDocumentVerificationsFiltersSlice = createSlice({
    name: FilterOrganizationNames.ORGANIZATION_DOCUMENT_VERIFICATIONS_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<
        OrganizationDocumentVerificationsFiltersState,
        OrganizationDocumentVerificationFiltersSliceState
    >(organizationDocumentVerificationsFiltersInitState),
});
