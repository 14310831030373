import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery();

const baseQueryWithInterceptor: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    return result;
};

export const publicApi = createApi({
    reducerPath: 'publicApi',
    baseQuery: baseQueryWithInterceptor,
    tagTypes: [],
    endpoints: () => ({}),
});
