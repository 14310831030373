import type { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/dist/query/react';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { RootState } from '@store/root-reducer';
import { baseQueryRequest } from '@utils/base-query-request';

const baseQuery = fetchBaseQuery();

const baseQueryWithInterceptor: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const state = api.getState() as RootState;

    const result = await baseQueryRequest({
        state,
        baseQuery,
        args,
        api,
        extraOptions,
        isUseCredentials: false,
    });

    return result;
};

export const sdkApi = createApi({
    reducerPath: 'sdkApi',
    baseQuery: baseQueryWithInterceptor,
    endpoints: () => ({}),
});
