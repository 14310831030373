import styled from 'styled-components';

export const LinkStyled = styled.a`
    color: ${(props) => props.theme.palette.primaryA1};
    ${(props) => props.theme.typography.button};
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        text-decoration: underline;
        opacity: 0.5;
    }
`;
