import React from 'react';

export const DashboardIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.95703" y="1.95703" width="8.6087" height="6.69565" rx="2" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
        <rect x="13.4355" y="15.3477" width="8.6087" height="6.69565" rx="2" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
        <rect x="13.4355" y="1.95703" width="8.6087" height="10.5217" rx="2" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
        <rect x="1.95703" y="11.5234" width="8.6087" height="10.5217" rx="2" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
    </svg>
);
