import React from 'react';
import type { IconProps } from '@models/types';

export const DocsVerificationIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='22'
        height='18'
        viewBox='0 0 22 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <path
            d='M4.33333 1H17.6667C19.5076 1 21 2.34315 21 4V14C21 15.6569 19.5076 17 17.6667 17H4.33333C2.49238 17 1 15.6569 1 14V4C1 2.34315 2.49238 1 4.33333 1Z'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M14.3325 9C13.1052 9 12.1102 8.10457 12.1102 7C12.1102 5.89543 13.1052 5 14.3325 5C15.5598 5 16.5547 5.89543 16.5547 7C16.5547 8.10457 15.5598 9 14.3325 9Z'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.66602 5H5.44379'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.66602 9H5.44379'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M16.5547 13H5.44358'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
