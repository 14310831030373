import type {
    OrganizationAccountInfoModel,
    WithPaginationRequest,
    WithPaginationResponse,
} from '@models';
import type { OrganizationRoleEnum } from '@models/enums';
import type { PaginationListResponse } from '@models/types';
import { getSearchParams } from '@utils/url';

import type {
    EventModel,
    OrganizationAccountModel,
    OrganizationAmlCheckModel,
    OrganizationApplicantModel,
    OrganizationDocumentVerificationModel,
    OrganizationInfoModel,
    OrganizationModel,
    OrganizationProductSettingsModel,
    OrganizationVerificationProfileModel,
    PaymentHistoryModel,
    ProductSettingsModel,
} from '../models';
import type {
    GetOrganizationAccountsRequest,
    GetOrganizationAmlChecksRequest,
    GetOrganizationApplicantsByOrganizationIdRequest,
    GetOrganizationsRequest,
    GetOrganizationVerificationsByOrganizationIdRequest,
} from '../models/requests';
import type { GetOrganizationEventTypeRequest } from '../models/requests/get-organization-event-type-request';

import { adminPanelApi } from './admin-panel-base-api';
import { AdminApiTagEnum } from './enums';

const URL_PREFIX = '/webapi/wl';

export const adminPanelOrganizationsApiSlice = adminPanelApi.injectEndpoints({
    endpoints: (build) => ({
        getAccountOrganizations: build.query<
            WithPaginationResponse<OrganizationAccountInfoModel>,
            void
        >({
            query: () => `${URL_PREFIX}/account/organization/list`,
        }),
        getOrganizationInfo: build.query<
            OrganizationInfoModel,
            { id: string; organizationId: string }
        >({
            query: ({ id, organizationId }) =>
                `${URL_PREFIX}/organization/${organizationId}/${id}/info`,
        }),
        getOrganizationsList: build.query<
            WithPaginationResponse<OrganizationModel>,
            GetOrganizationsRequest
        >({
            query: ({ current_org_id, ...rest }) =>
                `${URL_PREFIX}/organization/${current_org_id}/list?${getSearchParams(
                    rest,
                ).toString()}`,
        }),
        getOrganizationAccountsByOrganizationId: build.query<
            WithPaginationResponse<OrganizationAccountModel>,
            GetOrganizationAccountsRequest
        >({
            query: ({ id, organizationId, ...rest }) =>
                `${URL_PREFIX}/organization/${organizationId}/${id}/accounts?${getSearchParams(
                    rest,
                ).toString()}`,
        }),
        getOrganizationApplicantsByOrganizationId: build.query<
            WithPaginationResponse<OrganizationApplicantModel>,
            GetOrganizationApplicantsByOrganizationIdRequest
        >({
            query: ({ id, organizationId, ...rest }) =>
                `${URL_PREFIX}/organization/${organizationId}/${id}/applicants?${getSearchParams(
                    rest,
                ).toString()}`,
        }),
        getOrganizationVerificationsByOrganizationId: build.query<
            WithPaginationResponse<OrganizationDocumentVerificationModel>,
            GetOrganizationVerificationsByOrganizationIdRequest
        >({
            query: ({ id, organizationId, ...rest }) =>
                `${URL_PREFIX}/organization/${organizationId}/${id}/verifications?${getSearchParams(
                    rest,
                ).toString()}`,
        }),
        getOrganizationAmlChecksByOrganizationId: build.query<
            WithPaginationResponse<OrganizationAmlCheckModel>,
            GetOrganizationAmlChecksRequest
        >({
            query: ({ id, organizationId, ...rest }) =>
                `${URL_PREFIX}/organization/${organizationId}/${id}/aml-checks?${getSearchParams(
                    rest,
                ).toString()}`,
        }),
        getOrganizationVerificationProfilesByOrganizationId: build.query<
            WithPaginationResponse<OrganizationVerificationProfileModel>,
            WithPaginationRequest & { id: string; organizationId: string }
        >({
            query: ({ id, organizationId, ...rest }) =>
                `${URL_PREFIX}/organization/${organizationId}/${id}/verification-profiles?${getSearchParams(
                    rest,
                ).toString()}`,
        }),
        getOrganizationEventsByOrganizationId: build.query<
            WithPaginationResponse<EventModel>,
            GetOrganizationEventTypeRequest
        >({
            query: ({ id, organizationId, ...rest }) =>
                `${URL_PREFIX}/organization/${organizationId}/${id}/events?${getSearchParams(
                    rest,
                ).toString()}`,
        }),
        getOrganizationPaymentHistory: build.query<
            WithPaginationResponse<PaymentHistoryModel>,
            WithPaginationRequest & {
                id: string;
                organizationId: string;
                from?: string;
                to?: string;
            }
        >({
            query: ({ id, organizationId, ...rest }) =>
                `${URL_PREFIX}/operations/${organizationId}/organization/${id}/history?${getSearchParams(
                    rest,
                ).toString()}`,
        }),
        setOrganizationLogo: build.mutation<
            void,
            { file: File; type: 'default' | 'wide' | 'favicon'; id: string; organizationId: string }
        >({
            query: ({ id, organizationId, type = 'default', file }) => {
                const body = new FormData();

                body.append('file', file, file.name);

                return {
                    method: 'PUT',
                    url: `${URL_PREFIX}/organization/${organizationId}/${id}/logo?type=${type}`,
                    body,
                };
            },
            invalidatesTags: [AdminApiTagEnum.ORGANIZATIONS],
        }),
        deleteOrganizationLogo: build.mutation<
            void,
            { id: string; organizationId: string; type: 'default' | 'wide' | 'favicon' }
        >({
            query: ({ id, organizationId, type }) => ({
                method: 'DELETE',
                url: `${URL_PREFIX}/organization/${organizationId}/${id}/logo?type=${type}`,
            }),
            invalidatesTags: [AdminApiTagEnum.ORGANIZATIONS],
        }),
        updateOrganizationInfo: build.mutation<
            void,
            {
                id: string;
                organizationId: string;
                organization_name: string;
                organization_website: string;
            }
        >({
            query: ({ id, organizationId, organization_name, organization_website }) => ({
                method: 'POST',
                url: `${URL_PREFIX}/organization/${organizationId}/${id}/update`,
                body: {
                    organization_name,
                    organization_website,
                },
            }),
            invalidatesTags: [AdminApiTagEnum.ORGANIZATIONS],
        }),
        deleteAccountInOrganization: build.mutation<
            void,
            {
                id: string;
                organizationId: string;
                accountId: string;
            }
        >({
            query: ({ id, organizationId, accountId }) => ({
                method: 'DELETE',
                url: `${URL_PREFIX}/organization/${organizationId}/${id}/account/${accountId}`,
            }),
            invalidatesTags: [AdminApiTagEnum.ORGANIZATION_ACCOUNTS],
        }),
        addAccountToOrganization: build.mutation<
            void,
            {
                id: string;
                organizationId: string;
                full_name: string;
                email: string;
                role: OrganizationRoleEnum;
            }
        >({
            query: ({ id, organizationId, ...rest }) => ({
                method: 'POST',
                url: `${URL_PREFIX}/organization/${organizationId}/${id}/invite`,
                body: {
                    ...rest,
                },
            }),
            invalidatesTags: [AdminApiTagEnum.ORGANIZATION_ACCOUNTS],
        }),
        createOrganization: build.mutation<
            void,
            {
                organizationId: string;
                organization_name: string;
                organization_website: string;
            }
        >({
            query: ({ organizationId, ...rest }) => ({
                method: 'POST',
                url: `${URL_PREFIX}/organization/${organizationId}/create`,
                body: {
                    ...rest,
                },
            }),
            invalidatesTags: [AdminApiTagEnum.ORGANIZATIONS],
        }),
        changeAccountRoleInOrganization: build.mutation<
            void,
            {
                id: string;
                organizationId: string;
                account_id: string;
                role: OrganizationRoleEnum;
            }
        >({
            query: ({ id, organizationId, account_id, role }) => ({
                method: 'POST',
                url: `${URL_PREFIX}/organization/${organizationId}/${id}/account/${account_id}/role`,
                body: {
                    role,
                },
            }),
            invalidatesTags: [AdminApiTagEnum.ORGANIZATION_ACCOUNTS],
        }),
        getOrganizationProductSettings: build.query<
            OrganizationProductSettingsModel,
            {
                id: string;
                organizationId: string;
            }
        >({
            query: ({ id, organizationId, ...rest }) =>
                `${URL_PREFIX}/organization/${organizationId}/${id}/product-settings?${getSearchParams(
                    rest,
                ).toString()}`,
            providesTags: [AdminApiTagEnum.PRODUCT_SETTINGS],
        }),
        updateOrganizationProductSettings: build.mutation<
            void,
            {
                id: string;
                organizationId: string;
                body: ProductSettingsModel;
            }
        >({
            query: ({ id, organizationId, body }) => ({
                method: 'PUT',
                url: `${URL_PREFIX}/organization/${organizationId}/${id}/product-settings`,
                body,
            }),
            invalidatesTags: [AdminApiTagEnum.PRODUCT_SETTINGS],
        }),
        balanceDeposit: build.mutation<
            void,
            {
                id: string;
                organizationId: string;
                body: {
                    amount: number;
                };
            }
        >({
            query: ({ id, organizationId, body }) => ({
                method: 'POST',
                url: `${URL_PREFIX}/operations/${organizationId}/${id}/balance/deposit`,
                body,
            }),
            invalidatesTags: [AdminApiTagEnum.ORGANIZATION_BALANCE],
        }),
        getAccountEmails: build.query<
            PaginationListResponse<string, false>,
            { organizationId: string; id: string }
        >({
            query: ({ organizationId, id }) =>
                `${URL_PREFIX}/organization/${organizationId}/${id}/account-emails`,
        }),
    }),
});

export const {
    useLazyGetAccountOrganizationsQuery,
    useLazyGetOrganizationsListQuery,
    useLazyGetOrganizationAccountsByOrganizationIdQuery,
    useLazyGetOrganizationPaymentHistoryQuery,
    useGetOrganizationInfoQuery,
    useLazyGetOrganizationVerificationsByOrganizationIdQuery,
    useLazyGetOrganizationApplicantsByOrganizationIdQuery,
    useLazyGetOrganizationAmlChecksByOrganizationIdQuery,
    useLazyGetOrganizationVerificationProfilesByOrganizationIdQuery,
    useLazyGetOrganizationEventsByOrganizationIdQuery,
    useSetOrganizationLogoMutation,
    useDeleteOrganizationLogoMutation,
    useUpdateOrganizationInfoMutation,
    useDeleteAccountInOrganizationMutation,
    useAddAccountToOrganizationMutation,
    useChangeAccountRoleInOrganizationMutation,
    useCreateOrganizationMutation,
    useGetOrganizationProductSettingsQuery,
    useUpdateOrganizationProductSettingsMutation,
    useBalanceDepositMutation,
    useLazyGetAccountEmailsQuery,
} = adminPanelOrganizationsApiSlice;
