import React from 'react';

export const WalletIcon: React.FC = () => (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 3L15 4C16.6569 4 18 5.34315 18 7V8C19.1046 8 20 8.89543 20 10V12C20 13.1046 19.1046 14 18 14V15C18 16.6569 16.6569 18 15 18H3C1.34315 18 0 16.6569 0 15V3C0 1.34315 1.34315 0 3 0H12C13.6569 0 15 1.34315 15 3ZM2 3C2 2.44772 2.44772 2 3 2H12C12.5523 2 13 2.44772 13 3V4H3.41421C2.90322 4 2.40892 3.838 2 3.54244V3ZM16 14H14C12.8954 14 12 13.1046 12 12V10C12 8.89543 12.8954 8 14 8H16V7C16 6.44772 15.5523 6 15 6H3.41421C2.92869 6 2.45141 5.92001 2 5.76733V15C2 15.5523 2.44772 16 3 16H15C15.5523 16 16 15.5523 16 15V14ZM14 12V10H18V12H14Z"
            fill="currentColor"
        />
    </svg>
);
