export enum OperationTypeEnum {
    UNKNOWN = 'UNKNOWN',
    // TODO: remove deprecated use?
    AML = 'AML_SEARCH', // deprecated use AML_CHECK_ONEOFF
    DOCVER = 'DOCVER', // deprecated use DOCVER_ONEOF and DOCVER_W_AML_MONITORING_INITIAL
    AML_MONITORING = 'AML_MONITORING_ENABLED', // deprecated use AML_MONITORING_INITIAL AND AML_MONITORING_ONGOING
    DOCVER_MONITORING = 'DOCVER_MONITORING_ENABLED', // deprecated
    APPLICANT = 'APPLICANT', // deprecated, use AML_MONITORING_INITIAL instead
    //  --- ACTUAL ---
    BALANCE_TRANSFER = 'BALANCE_TRANSFER',
    POA_GEN = 'POA_GEN',
    BANKBOOK_CHECK_ONEOFF = 'BANKBOOK_CHECK_ONEOFF',
    // have links in operational history
    DEPOSIT = 'DEPOSIT',
    AML_MONITORING_INITIAL = 'AML_MONITORING_INITIAL',
    AML_MONITORING_ONGOING = 'AML_MONITORING_ONGOING',
    DOCVER_W_AML_MONITORING_INITIAL = 'DOCVER_W_AML_MONITORING_INITIAL',
    LIVENESS_ONEOFF = 'LIVENESS_ONEOFF',
    DOCVER_ONEOFF = 'DOCVER_ONEOFF',
    AML_CHECK_ONEOFF = 'AML_CHECK_ONEOFF',
    TRANSACTION_MONITORING_CHECK = 'TRANSACTION_MONITORING_CHECK',
}
