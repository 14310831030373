import React from 'react';

export const ApiIcon: React.FC = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6 3C4.34315 3 3 4.34315 3 6V10C3 10.5523 2.55228 11 2 11V13C2.55228 13 3 13.4477 3 14V18C3 19.6569 4.34315 21 6 21H7C7.55228 21 8 20.5523 8 20C8 19.4477 7.55228 19 7 19H6C5.44772 19 5 18.5523 5 18V14C5 13.2316 4.71115 12.5308 4.2361 12C4.71115 11.4692 5 10.7684 5 10V6C5 5.44772 5.44772 5 6 5H7C7.55228 5 8 4.55228 8 4C8 3.44772 7.55228 3 7 3H6Z"
            fill="currentColor"
        />
        <path
            d="M21 6C21 4.34315 19.6569 3 18 3H17C16.4477 3 16 3.44772 16 4C16 4.55228 16.4477 5 17 5H18C18.5523 5 19 5.44772 19 6V10C19 10.7684 19.2889 11.4692 19.7639 12C19.2889 12.5308 19 13.2316 19 14V18C19 18.5523 18.5523 19 18 19H17C16.4477 19 16 19.4477 16 20C16 20.5523 16.4477 21 17 21H18C19.6569 21 21 19.6569 21 18V14C21 13.4477 21.4477 13 22 13V11C21.4477 11 21 10.5523 21 10V6Z"
            fill="currentColor"
        />
        <path
            d="M10 12C10 12.5523 9.55228 13 9 13C8.44772 13 8 12.5523 8 12C8 11.4477 8.44772 11 9 11C9.55228 11 10 11.4477 10 12Z"
            fill="currentColor"
        />
        <path
            d="M16 12C16 12.5523 15.5523 13 15 13C14.4477 13 14 12.5523 14 12C14 11.4477 14.4477 11 15 11C15.5523 11 16 11.4477 16 12Z"
            fill="currentColor"
        />
        <path
            d="M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z"
            fill="currentColor"
        />
    </svg>
);
