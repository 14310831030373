import React from 'react';

export const PepSanctionsIcon: React.FC = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 3C0 1.34315 1.34315 0 3 0H7C7.55228 0 8 0.447715 8 1C8 1.55228 7.55228 2 7 2H3C2.44772 2 2 2.44772 2 3V7C2 7.55228 1.55228 8 1 8C0.447715 8 0 7.55228 0 7V3Z"
            fill="currentColor"
        />
        <path
            d="M14 1C14 0.447715 14.4477 0 15 0H19C20.6569 0 22 1.34315 22 3V7C22 7.55228 21.5523 8 21 8C20.4477 8 20 7.55228 20 7V3C20 2.44772 19.5523 2 19 2H15C14.4477 2 14 1.55228 14 1Z"
            fill="currentColor"
        />
        <path
            d="M1 14C1.55228 14 2 14.4477 2 15V19C2 19.5523 2.44772 20 3 20H7C7.55228 20 8 20.4477 8 21C8 21.5523 7.55228 22 7 22H3C1.34315 22 0 20.6569 0 19V15C0 14.4477 0.447715 14 1 14Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.4447 15.0305C16.4111 13.9654 17 12.5515 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17C12.036 17 13.0106 16.7374 13.861 16.2752L17.2929 19.7071C17.6834 20.0976 18.3166 20.0976 18.7071 19.7071C19.0976 19.3166 19.0976 18.6834 18.7071 18.2929L15.4447 15.0305ZM11 7C8.79086 7 7 8.79086 7 11C7 13.2091 8.79086 15 11 15C11.8921 15 12.7159 14.708 13.3812 14.2143C13.3938 14.2044 13.4066 14.1947 13.4196 14.1855C14.38 13.4548 15 12.2998 15 11C15 8.79086 13.2091 7 11 7Z"
            fill="currentColor"
        />
    </svg>
);
