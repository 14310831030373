import type React from 'react';
import { type ReactNode, Children, isValidElement } from 'react';
import type { DateModel, LocationDataModel, PepReportSourceModel } from '@models';
import { DateFormatEnum, PepTagEnum, SexEnum, VerificationFileSizeEnum } from '@models/enums';
import dayjs from 'dayjs';
import { filter, some } from 'lodash';

export function isFileSizeInRange(size: number, min = 50, max = 8192) {
    if (!size) {
        return false;
    }

    const tempSizeFile = size / 1024;

    if (tempSizeFile >= min && tempSizeFile <= max) {
        return VerificationFileSizeEnum.SUCCESS;
    }

    return max < tempSizeFile ? VerificationFileSizeEnum.BIG : VerificationFileSizeEnum.SMALL;
}

export const fileToBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export const base64ToBlob = (str: string, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(str);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
};

export const base64ToFile = async (str: string, fileName: string, type = 'image/jpeg') =>
    fetch(str)
        .then((res) => res.blob())
        .then((blob) => new File([blob], fileName, { type }));

export const getAddressString = (address: LocationDataModel) =>
    [address.postal_code, address.region, address.country, address.city, address.address]
        .filter((str) => !!str)
        .join(', ');

export const getStrTrim = (str: string) => {
    if (!str) {
        return str;
    }

    return str.replace(/\s+/g, ' ').trim();
};

export const getUserInitials = (name: string) => {
    if (!name) {
        return '';
    }
    const split = getStrTrim(name).split(' ');

    return split.map((item: string) => item[0].toUpperCase());
};

const numberRegexp = /^[0-9]+$/;

export const isNumberValue = (str: string) => numberRegexp.test(str);

const decimalRegexp = /^\d*\.?\d*$/;

export const isDecimalValue = (str: string) => decimalRegexp.test(str);

export const sortTags = (tags: PepTagEnum[]) => {
    const indexTags = {
        [PepTagEnum.SANCTIONS]: 0,
        [PepTagEnum.TERRORISM]: 1,
        [PepTagEnum.CRIMINAL]: 2,
        [PepTagEnum.LEAKS]: 3,
        [PepTagEnum.DEBARRED]: 4,
        [PepTagEnum.PEP]: 5,
        [PepTagEnum.FINANCE]: 6,
        [PepTagEnum.LEGAL]: 7,
        [PepTagEnum.SOCIAL]: 8,
        [PepTagEnum.MEDIA]: 9,
        [PepTagEnum.UNOFFICIAL_SOURCE]: 10,
        [PepTagEnum.COMPANY_REGISTRY]: 11,
        [PepTagEnum.ASSOCIATE]: 12,
        [PepTagEnum.PUBLIC_INTEREST]: 13,
    };

    return [...tags]
        .filter((tag) => tag !== PepTagEnum.UNKNOWN_TAG)
        ?.sort(
            (a, b) =>
                indexTags[a as unknown as keyof typeof indexTags] -
                indexTags[b as unknown as keyof typeof indexTags],
        );
};

export const splitTags = (tags: PepTagEnum[], tagsCount = 4) => {
    const showTags = tags?.slice(0, tagsCount) || null;
    const hideTags = tags?.slice(tagsCount) || null;

    return {
        showTags,
        hideTags,
    };
};

export const getTagVariant = (tag: PepTagEnum) => {
    switch (tag) {
        case PepTagEnum.SANCTIONS:
        case PepTagEnum.TERRORISM:
        case PepTagEnum.CRIMINAL:
            return 'error';
        case PepTagEnum.PEP:
            return 'green';
        case PepTagEnum.LEAKS:
        case PepTagEnum.DEBARRED:
            return 'yellow';
        case PepTagEnum.SOCIAL:
            return 'violet';
        default:
            return 'primary';
    }
};

export const sleep = (timeout: number) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve(null);
        }, timeout);
    });

export const getCountTags = (
    sources: PepReportSourceModel[] | undefined,
): Record<string, number> | undefined => {
    if (!sources?.length) {
        return;
    }

    // eslint-disable-next-line consistent-return
    return sources?.reduce((acc, source) => {
        if (source.tags) {
            source.tags.forEach((tag) => {
                if (tag in acc) {
                    acc = {
                        ...acc,
                        [tag]: acc[tag as keyof typeof acc] + 1,
                    };
                } else {
                    acc = {
                        ...acc,
                        [tag]: 1,
                    };
                }
            });
        }

        return acc;
    }, {});
};

export const getLocation = (location: LocationDataModel) => {
    const locationStr = [location.postal_code, location.region, location.city, location.address]
        .filter((str) => !!str)
        .join(', ');

    return locationStr.length ? locationStr : location?.address || null;
};

export const isTouchDevice = () =>
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-expect-error check IE touch
    navigator.msMaxTouchPoints > 0;

export const getSharePercentage = (total: number, value: number): string => {
    if (total === 0) {
        return `0`;
    }

    return `${((value / total) * 100).toFixed(2)}%`;
};
export const getCurrenDate = (startDate?: DateModel, endDate?: DateModel) => {
    let res: string | undefined = '';

    function spiteDate(date: string) {
        if (!date) {
            return undefined;
        }

        return date.split('-');
    }

    function getDate(date: DateModel | undefined) {
        if (!date || !Object.values(date)) {
            return '';
        }
        if (date.lte === date.gte) {
            return date.lte;
        }
        const gteSplit = date.gte ? spiteDate(date.gte) : undefined;
        const lteSplit = date.lte ? spiteDate(date.lte) : undefined;

        // if the days are different, then reduce to a month
        if (gteSplit?.[2] !== lteSplit?.[2] && gteSplit?.[1] === lteSplit?.[1] && gteSplit) {
            return `${gteSplit[0]}-${gteSplit[1]}`;
        }
        // if the months are different, then shorten it to a year
        if (gteSplit?.[1] !== lteSplit?.[1] && gteSplit?.[0] === lteSplit?.[0] && gteSplit) {
            return gteSplit[0];
        }

        return date.lte || date.gte;
    }

    function getDateFormat(date: string) {
        const count = date.match(/-/g)?.length;

        if (count === 0) {
            return DateFormatEnum.YEAR;
        }
        if (count === 1) {
            return DateFormatEnum.YEAR_MONTH;
        }
        if (count === 2) {
            return DateFormatEnum.YEAR_MONTH_DAY;
        }

        return DateFormatEnum.YEAR;
    }

    if (
        getDate(startDate) === getDate(endDate) ||
        (startDate && !!Object.values(startDate).length)
    ) {
        const resDate = getDate(startDate);

        res = resDate ? dayjs(resDate).format(getDateFormat(resDate)) : '';
    }
    if (endDate && Object.values(endDate)) {
        const resDate = getDate(endDate);

        res += resDate ? ` - ${dayjs(resDate).format(getDateFormat(resDate))}` : '';
    }

    return res;
};

export const isAndroid = () => {
    const ua = navigator.userAgent.toLowerCase();

    return ua.indexOf('android') > -1;
};

export const getEnvironmentVideoConstraints = async () => {
    if (isAndroid()) {
        try {
            // const devices = await navigator.mediaDevices.enumerateDevices();
            // let final: MediaDeviceInfo | null = null;
            // const videoDevices: MediaDeviceInfo[] = [];
            //
            // devices.forEach((device) => {
            //     if (device.kind === 'videoinput') {
            //         videoDevices.push(device);
            //         if (
            //             // @ts-expect-error facingMode exist sometimes
            //             device.facingMode === 'environment' ||
            //             device.label.indexOf('facing back') >= 0
            //         ) {
            //             final = device;
            //         }
            //     }
            // });
            //
            // const totalCameras = videoDevices.length;
            //
            // if (final == null) {
            //     final = videoDevices[totalCameras - 1];
            // }

            return {
                audio: false,
                video: {
                    facingMode: { ideal: 'environment' },
                },
            };
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Error during getEnvironmentVideoConstraints', e);
        }
    }

    return {
        audio: false,
        video: {
            facingMode: 'environment',
        },
    };
};

export const getGenderDocVerMrz = (sex: SexEnum) => {
    if (!sex) {
        return undefined;
    }

    return sex === SexEnum.MALE ? 'documents.fields.male' : 'documents.fields.female';
};

export const scrollTop = () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
};

export const isDesktop = () => {
    // @ts-expect-error opera exist
    const navigatorAgent = navigator.userAgent || navigator.vendor || window.opera;

    return !(
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            navigatorAgent,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([ /])|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([-01])|47|mc|nd|ri)|sgh-|shar|sie([-m])|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            navigatorAgent.substr(0, 4),
        )
    );
};

export const filteredAmlSources = (sources: PepReportSourceModel[]) => {
    if (!sources) {
        return undefined;
    }

    const res = sources.reduce((newSources, currentSource) => {
        if (!newSources?.find((source) => source?.source_id === currentSource?.source_id)) {
            newSources.push(currentSource);
        }

        return newSources;
    }, [] as PepReportSourceModel[]);

    return res;
};

const hasChildren = (
    element: ReactNode,
): element is React.ReactElement<{ children: ReactNode | ReactNode[] }> =>
    isValidElement<{ children?: ReactNode[] }>(element) && Boolean(element.props.children);

export const childToString = (child?: ReactNode): string => {
    if (typeof child === 'undefined' || child === null || typeof child === 'boolean') {
        return '';
    }

    if (JSON.stringify(child) === '{}') {
        return '';
    }

    return (child as number | string).toString();
};

export const getTextContent = (children: ReactNode | ReactNode[]): string => {
    if (!(children instanceof Array) && !isValidElement(children)) {
        return childToString(children);
    }

    return Children.toArray(children)
        .reduce((text: string, child: ReactNode): string => {
            let newText = '';

            if (hasChildren(child)) {
                newText = getTextContent(child.props.children);
            } else if (isValidElement(child)) {
                newText = '';
            } else {
                newText = childToString(child);
            }

            return text.concat(` ${newText}`);
        }, '')
        .trim();
};

export const filterByArrayOption = (
    mainArray: Array<{ label: string; value: string }>,
    secondArray: Array<{ label?: string; value: string }>,
) =>
    filter(mainArray, (item) => some(secondArray, (filterItem) => filterItem.value === item.value));
