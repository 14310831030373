import { createGlobalStyle } from 'styled-components';

export const MenuItemGlobalStyles = createGlobalStyle`
  .sub-menu-popper-container .MuiTooltip-tooltip {
    padding: 0;
    margin-left: 32px;
    margin-top: 29px !important;
    border-radius: 8px;
    background-color: transparent;
  }

  .sub-menu-popper-container .MuiTooltip-tooltip > div > a {
    padding-right: 24px;
  }

  .sub-menu-popper-container .MuiTooltip-tooltip > div > a:first-child {
    border-radius: 8px 8px 0 0;
  }

  .sub-menu-popper-container .MuiTooltip-tooltip > div > a:last-child {
    border-radius: 0 0 8px 8px;
  }
  
  .sub-menu-popper-container .MuiTooltip-tooltip > div > a:only-child {
    border-radius: 8px;
  }
`;
