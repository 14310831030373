import React from 'react';

export const MessageIcon: React.FC = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.00167825 9.19248C-0.0981984 3.95087 4.27307 -0.328939 9.57909 0.0199208C14.087 0.296544 17.7081 3.94573 17.9832 8.44574C18.083 10.0918 17.7339 11.6671 17.0569 13.0208C16.9483 13.2378 16.9317 13.4968 17 13.7015L17.0026 13.7094L17.7879 16.1319L17.7942 16.1546C18.0647 17.1463 17.1566 18.0526 16.1662 17.7826L16.1557 17.7798L13.5947 17.0514C13.345 16.9919 13.1065 17.0147 12.9297 17.096C11.6734 17.7215 10.2466 18.0444 8.75212 17.9952C3.99606 17.8681 0.101849 13.9489 0.00171848 9.19448L0.00167825 9.19248ZM2.0013 9.15336C2.0795 12.8416 5.11893 15.8989 8.80837 15.996L8.81538 15.9961C9.98257 16.035 11.0846 15.7824 12.0474 15.3011L12.057 15.2964L12.0666 15.2918C12.7307 14.9773 13.4618 14.9566 14.093 15.1143L14.1086 15.1182L15.4836 15.5093L15.1012 14.3297C14.8601 13.6023 14.9328 12.7966 15.2681 12.1261C15.7914 11.0799 16.0648 9.85485 15.9869 8.56778M2.0013 9.15336C1.92393 5.06296 5.33165 1.74389 9.4498 2.01574L9.4547 2.01605C12.9483 2.22947 15.7727 5.06888 15.9869 8.56778"
            fill="currentColor"
        />
    </svg>
);
